<template>
  <main v-if="pagePayload.homepage" class="page">
    <div class="bg-dark">
      <div class="decoration-cylinder"></div>
      <div class="container">
        <div class="mockup-move-container"></div>
        <header v-if="pagePayload.homepage.header" class="header">
          <div class="content-container">
            <div class="content">
              <h1 class="title" v-html="pagePayload.homepage.header.title"></h1>
              <div
                v-if="pagePayload && pagePayload.homepage && pagePayload.homepage.headerLogos"
                class="logos-container"
              >
                <img
                  v-for="logo in pagePayload.homepage.headerLogos"
                  :key="logo.mediaItemUrl"
                  class="logo"
                  v-lazy-load :data-src="logo.mediaItemUrl"
                  width="60"
                  height="30"
                  alt=""
                  role="presentation"
                />
              </div>
              <div>
                <AppButton class="video-button" disable-aos @click.native="openVideoPopup"
                  ><PlayIcon />{{ pagePayload.homepage.header.buttonContent }}</AppButton
                >
              </div>
            </div>
          </div>

          <AppAnimatedMockup
            :v-if="pagePayload && pagePayload.homepage.headerVideo.acfMockupVideo"
            :file-url="pagePayload.homepage.headerVideo.acfMockupVideo.mediaItemUrl"
            :mime-type="pagePayload.homepage.headerVideo.acfMockupVideo.mimeType"
          />
        </header>
        <section v-if="pagePayload.homepage.slider" class="section --slider">
          <div class="extra-wrapper">
            <h2 v-if="pagePayload.homepage.slider.title" class="title --light">
              {{ pagePayload.homepage.slider.title }}
            </h2>
            <p v-if="pagePayload.homepage.slider.subtitle" class="subtitle --light --narrow">
              {{ pagePayload.homepage.slider.subtitle }}
            </p>
          </div>
          <span ref="trigger-slider"></span>
          <LazyHydrate when-visible>
            <HomepageSlider class="slider" :slides="pagePayload.homepage.slider.slides" />
          </LazyHydrate>

          <div>
            <AppButton
              :to="`${$route.path === '/' ? `` : `${$route.path}/`}${ROUTES.SALES_APP_3D.path}`"
              title="3d sales app"
              >{{ pagePayload.homepage.slider.buttonContent || '' }} <ArrowLinearIcon
            /></AppButton>
          </div>
        </section>
      </div>
    </div>
    <LazyHydrate when-visible>
      <AppWebSolutionsSection class="--with-wrapper --web-sales-solutions" />
    </LazyHydrate>
    <span ref="trigger-for-investments-map"></span>

    <LazyHydrate :when-visible="{ rootMargin: '500px' }">
      <AppInvestmentsMapSection />
    </LazyHydrate>
    <LazyHydrate when-visible> <AppClientsSection class="--with-wrapper" /> </LazyHydrate>
    <LazyHydrate when-visible>
      <AppCaseStudiesSection class="--with-wrapper" />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <AppTestimonialsSection
        v-if="testimonialsData"
        class="--with-wrapper"
        :testimonials="testimonialsData"
      />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <AppVideoPopup :show="showVideoPopup" @close="closeVideoPopup">
        <video
          v-if="
            pagePayload.homepage.headerVideo.acfModalVideo &&
            pagePayload.homepage.headerVideo.sourceForModal === 'Video'
          "
          v-lazy-load :data-src="pagePayload.homepage.headerVideo.acfModalVideo.mediaItemUrl"
          :type="pagePayload.homepage.headerVideo.acfModalVideo.mimeType"
          autoplay
          title="resimo video"
          class="popup-video"
          loop
          playsinline
          controls
        >
          Your browser does not support the video tag.
        </video>
        <iframe
          v-if="
            pagePayload.homepage.headerVideo.acfModalVideoUrl &&
            pagePayload.homepage.headerVideo.sourceForModal === 'Video url'
          "
          v-lazy-load :data-src="pagePayload.homepage.headerVideo.acfModalVideoUrl"
          allowfullscreen
          scrolling="none"
          class="iframe-in-modal"
        ></iframe>
      </AppVideoPopup>
    </LazyHydrate>
  </main>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { ROUTES } from '@/utils/routes'
import { getParsedSeoTags } from '@/utils/getParsedSeoTags.js'

export default {
  name: 'HomepagePage',
  components: {
    LazyHydrate,
    AppButton: () => import('@/components/common/AppButton'),
    HomepageSlider: () => import('../components/homepage/HomepageSlider'),
    ArrowLinearIcon: () => import('@/components/icons/ArrowLinearIcon'),
    AppVideoPopup: () => import('@/components/common/AppVideoPopup'),
    AppWebSolutionsSection: () => import('@/components/common/sections/AppWebSolutionsSection'),
    AppInvestmentsMapSection: () => import('@/components/common/sections/AppInvestmentsMapSection'),
    AppAnimatedMockup: () => import('@/components/common/AppAnimatedMockup'),
    AppCaseStudiesSection: () => import('@/components/common/sections/AppCaseStudiesSection'),
    AppClientsSection: () => import('@/components/common/sections/AppClientsSection'),
    AppTestimonialsSection: () => import('@/components/common/sections/AppTestimonialsSection'),
    PlayIcon: () => import('@/components/icons/PlayIcon'),
  },
  scrollToTop: false,
  async asyncData({ $cacheFetch, route, $graphql }) {
    const variables = { id: route.meta[0].id }
    const lang = route.meta[0].lang
    return await $cacheFetch(
      { key: `${route.path}-${lang}-${route.meta[0].id}`, expire: 99999 },
      async () => {
        const { default: Homepage } = await import('../graphql/HomepageQueries.graphql')
        const { page } = await $graphql.default.request(Homepage, variables)
        const pagePayload = page
        return { pagePayload, variables, lang }
      }
    )
  },
  data() {
    return {
      ROUTES,
      showVideoPopup: false,
      pagePayload: null,
      pageReady: false,
      showInvestmentMap: false,
      investmentMapTriggerRef: null,
      showDynamicComponents: false,
      showSlider: false,
      sliderTriggerRef: null,
    }
  },
  head() {
    if (this.pagePayload) {
      return {
        ...getParsedSeoTags(this.pagePayload?.seo?.fullHead, this.$route),
        title: 'Resimo - Interactive 3D sales application for Real Estate',
        htmlAttrs: {
          lang: this.lang,
        },
        link: [
          {
            rel: 'canonical',
            href: `${process.env.ORIGIN_URL}${this.$route.path}`,
          },
          {
            rel: 'preload',
            as: 'image',
            imagesrcset: this.pagePayload.homepage.slider.slides[0].image.srcSet,
          },
        ],
      }
    }
  },
  computed: {
    getCurrentLanguage() {
      return this.$store.getters.getCurrentLanguage
    },
    getLangToChange() {
      return this.$store.getters.getLangToChange
    },
    testimonialsData() {
      if (this.pagePayload?.homepage.testimonials) {
        return this.pagePayload.homepage.testimonials.map((item) => ({
          image: item.testimonials.image,
          ...item.testimonials[this.getLangToChange],
        }))
      }
      return {}
    },
  },
  watch: {
    getLangToChange() {
      this.getData()
    },
    lang(v) {
      const langObj = {
        lang: v,
        name: this.$route.meta[0].name,
      }
      this.setLanguage(langObj)
    },
  },
  beforeMount() {
    window.scrollTo(0, 0)
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    this.$nextTick(() => {
      this.$nuxt.$loading.finish(true)
      // eslint-disable-next-line nuxt/no-env-in-hooks
      this.pageReady = true
      // this.investmentMapTriggerRef = this.$refs['trigger-for-investments-map']
      // this.sliderTriggerRef = this.$refs['trigger-slider']
      // console.log(this.sliderTriggerRef, ' this.sliderTriggerRef')
      // console.log(this.investmentMapTriggerRef, 'this.investmentMapTriggerRef')

      // if (!this.investmentMapTriggerRef) this.showInvestmentMap = true
      // else {
      //   this.showInvestmentMap = isElementInViewport(this.investmentMapTriggerRef)
      // }

      // if (!this.showInvestmentMap) {
      //   window.addEventListener('scroll', this.handleInvestmentForScroll())
      // }
    })
  },
  methods: {
    setLanguage() {
      this.$store.dispatch('setLanguage')
    },
    closeVideoPopup() {
      this.showVideoPopup = false
      document?.body?.classList.remove('--overflow-hidden')
    },
    openVideoPopup() {
      this.showVideoPopup = true
      document?.body?.classList.add('--overflow-hidden')
    },
    async getData() {
      this.lang = this.$route.meta.lang
      const variables = { id: this.$route.meta.id }
      this.pageReady = false
      const { default: Homepage } = await import('../graphql/HomepageQueries.graphql')
      const { page } = await this.$graphql.default.request(Homepage, variables)

      this.pagePayload = page
      this.pageReady = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/Homepage';
</style>
