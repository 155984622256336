import { html2json } from 'html2json'

const removeHtmlComments = (htmlString) => {
  return htmlString.replace(/<!--[\s\S]*?-->/g, '')
}

export const getParsedSeoTags = (fullHead, route) => {
  const cleanHtml = removeHtmlComments(fullHead)
  const rawJson = html2json(cleanHtml)
  const metas = rawJson.child.filter((tag) => tag.node === 'element')
  const metasArray = metas.filter((m) => m.tag === 'meta').map((m) => m.attr)
  const meta = []
  for (let i = 0; i < metasArray.length; i++) {
    const obj = {
      name: '',
      content: '',
      key: '',
    }
    obj.name = metasArray[i].property ? metasArray[i].property : metasArray[i].name
    obj.content =
      typeof metasArray[i].content === 'string'
        ? metasArray[i].content
        : metasArray[i].content.join(' ')
    obj.key = metasArray[i].property ? metasArray[i].property : metasArray[i].name
    if (obj.name === 'og:url')
      obj.content = `https://resimo.com${route.path !== '/' ? route.path : ''}`
    meta.push(obj)
  }

  return {
    meta,
  }
}
